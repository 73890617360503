import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorization:"",
    userId:"",
  },
  mutations: {
    setToken(state){
      state.Authorization=localStorage.Authorization
    },
    setUid(state,userId){
      state.userId=userId
    }
  },
  getters: {
    doneTodosCount: (state) => {//通过属性访问
      return state.Authorization
    }
  },
  actions: {
  },
  modules: {
  }
})
