import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import VueCookies from 'vue-cookies'
import store from './store'

// import * as dd from 'dingtalk-jsapi'
import dd from 'gdt-jsapi';

Vue.use(VueCookies)
Vue.prototype.$axios = axios

import VConsole from 'vconsole';
// if (window.location.href.indexOf('vconsole=true') != -1) {
  // const vConsole = new VConsole();
// }

import {queue} from './utils/zzdutil'
Vue.prototype.queue=queue;

Vue.prototype.$dd = dd

router.beforeEach((to, from, next) => {
  queue(to.name,to.meta.title,to.path)
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
