import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Zhuye',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/main.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/home',
    name: 'Home',
    meta:{
      title:"零售药店全场景智控系统"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/main.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "about" */ '../views/500.vue'),
  },
  {
    path: '/tip',
    name: 'tip',
    component: () => import(/* webpackChunkName: "about" */ '../views/tip.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/today',
    name: 'Today',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/today'),
    meta: {
      title: "零售药店全场景智控系统"
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/history'),
    meta: {
      title: "零售药店全场景智控系统"
    }
  },
  {
    path: '/archives',
    name: 'Archives',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/archives'),
    meta: {
      title: "零售药店全场景智控系统"
    }
  },
  {
    path: '/task',
    name: 'Task',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/task'),
    meta: {
      title: "零售药店全场景智控系统"
    }
  },
  {
    path: '/disposal',
    name: 'Disposal',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/disposal'),
    meta: {
      title: "零售药店全场景智控系统"
    }
  },
  {
    path: '/disposal/:id',
    name: 'DisposalDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/disposalDetail'),
    meta: {
      title: "零售药店全场景智控系统"
    }
  },
  {
    path: '/xiafa',
    name: 'Xiafa',
    component: () => import('../views/xiafa/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/inspector',
    name: 'Inspector',
    component: () => import('../views/inspector/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/mine/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/add',
    name: 'Add',
    component: () => import('../views/add/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/begin',
    name: 'Begin',
    component: () => import('../views/begin/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/taskHand',
    name: 'taskHand',
    component: () => import('../views/begin/taskHand.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/notice/index.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  },
  {
    path: '/addTask',
    name: 'addTask',
    component: () => import('../views/home/add.vue'),
    meta:{
      title:"零售药店全场景智控系统"
    }
  }

]

const router = new VueRouter({
  routes
})

export default router
