//接收3个参数：page_id，page_name，page_url
export function queue(metaId, metaName,metaPath) {
    (function(w, d, s, q, i) {
      w[q] = w[q] || [];
      var f = d.getElementsByTagName(s)[0],j = d.createElement(s);
      j.async = true;
      j.id = 'beacon-aplus';
      j.src = 'https://alidt.alicdn.com/alilog/mlog/aplus_cloud.js';
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'aplus_queue');
    
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-rhost-v', 'alog-api.ding.zj.gov.cn']
    });
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-rhost-g', 'alog-api.ding.zj.gov.cn']
    });
    var u = navigator.userAgent
    var isAndroid = u.indexOf('Android') > -1
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['appId', isAndroid ? '28302650' : isIOS ? '28328447' : '47130293']
    });



  //单页应用或“单个页面”需异步补充PV日志参数还需进行如下埋点：
  //console.log('执行埋点',metaId, metaName,metaPath,localStorage.userId||0)
  //基础埋点      
  aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-waiting', 'MAN']
      });//
      // 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
      aplus_queue.push({
      'action':'aplus.sendPV',
      'arguments':[{
          is_auto: false
          }, {
              //当前你的应用信息，此两行按应用实际参数修改，不可自定义。
              sapp_id: '39510',
              sapp_name: 'ydzk-jhscjgj',
              // 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
              page_id: metaId,//'页面ID，与page 参数配合使用，保证唯一性',
              page_name: metaName,//'页面中文名称'
              page_url: metaPath
          }]
      })
    console.log('执行埋点sendPV',metaId, metaName,metaPath,localStorage.userId||0)
      // 用户信息埋点
      //如采集用户信息是异步行为需要先执行这个BLOCK埋点
      aplus_queue.push({
          action: 'aplus.setMetaInfo',
          arguments: ['_hold', 'BLOCK']
      });
      // 设置用户ID，用户设备ID可不做上报，若上报可使用开放平台JSAPI获取UUID
      //用户id需要埋政钉用户真实信息，用户ID必须用accountId，可通过开放平台“获取用户详情”接口获取。
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_id", localStorage.userId]
      });
      // 如采集用户信息是异步行为，需要先设置完用户信息后再执行这个START埋点
      // 此时被block住的日志会携带上用户信息逐条发出
      aplus_queue.push({
          action: 'aplus.setMetaInfo',
          arguments: ['_hold', 'START']
      });
      //埋点end
  
};


